import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/rapporti/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token, id_stabilimento) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/rapporti/get_all?token="+token+"&id_stabilimento="+id_stabilimento).then(response => {
            return response.data;
        });
    },
    async getAllFromAttivita(token, id_attivita, id_reparto) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/rapporti/get_all_from_attivita?token="+token+"&id_attivita="+id_attivita+'&id_reparto='+id_reparto).then(response => {
            return response.data;
        });
    },
    async getOne(token, _id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/rapporti/get_one?token="+token+"&_id="+_id).then(response => {
            return response.data;
        });
    },
    async getOneFromPoint(token, _id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/rapporti/get_one_from_point?token="+token+"&_id="+_id).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/rapporti/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/rapporti/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async sendFileVideo(token, id_rapporto, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/rapporti/send_file_video?token="+token+"&id_rapporto="+id_rapporto, params).then(response => {
            return response.data;
        });
    },
    async sendFileImmagini(token, id_rapporto, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/rapporti/send_file_immagini?token="+token+"&id_rapporto="+id_rapporto, params).then(response => {
            return response.data;
        });
    },
    async removeFileVideo(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/rapporti/remove_file_video?token="+token, params).then(response => {
            return response.data;
        });
    },
}